import { isFunction } from 'lodash-es';
import React from 'react';
import type { PopoverProps } from 'react-aria-components';
import { Popover } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { popover } from '../../electrons/popover';

interface _PopoverProps extends Omit<PopoverProps, 'children'> {
  children: React.ReactNode;
}

export function _Popover({ children, className, ...props }: _PopoverProps) {
  return (
    <Popover
      className={(bag) => twMerge(popover(), isFunction(className) ? className(bag) : className)}
      ref={(ref) => ref?.addEventListener('touchend', (e) => e.preventDefault())}
      {...props}>
      {children}
    </Popover>
  );
}

export { _Popover as Popover };
